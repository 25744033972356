<template>
  <div>
    <v-row class="mx-1">
      <v-col cols="12">
        <v-card id="card-custom" class="px-2 py-4 rounded-lg">
          <v-card-actions>
            <p class="headline-color px-2">Group NIP</p>
          </v-card-actions>
          <v-divider />
          <v-row class="mx-2" id="card-custom">
            <v-col cols="12" xl="3" lg="3" md="3" sm="3" xs="12"> </v-col>
            <v-col cols="12" xl="3" lg="3" md="3" sm="3" xs="12">
              <v-select
                placeholder="Unit Utama"
                :items="units"
                v-model="filter.unit_utama_id"
                :loading="loadingUnit"
                outlined
                dense
                hide-details
                clearable
                item-text="singkatan"
                item-value="unit_utama_id"
                @change="changeUnit(1)"
                @click:clear="changeUnit(1)"
              ></v-select>
            </v-col>
            <v-col cols="12" xl="3" lg="3" md="3" sm="3" xs="12">
              <v-select
                placeholder="Unit Kerja"
                :items="units2"
                v-model="filter.unit_kerja_2_id"
                :loading="loadingUnit"
                outlined
                dense
                hide-details
                clearable
                item-text="singkatan"
                item-value="unit_kerja_2_id"
              ></v-select>
            </v-col>
            <v-col cols="12" xl="3" lg="3" md="3" sm="3" xs="12">
              <v-text-field
                v-model="filter.search"
                placeholder="Cari"
                outlined
                dense
                hide-details
                prepend-inner-icon="search"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row id="card-custom">
            <v-col cols="12" xl="8" lg="8" md="8" sm="12" xs="12">
              <v-btn
                text
                class="text-capitalize caption headline-color"
                color="primary"
                @click="
                  form.groupid = null;
                  visible = true;
                "
                >Tambah Group NIP<v-icon class="ml-2" color="primary"
                  >add_circle</v-icon
                ></v-btn
              >
            </v-col>
            <v-col cols="12" xl="4" lg="4" md="4" sm="12" xs="12"> </v-col>
          </v-row>
          <v-data-table
            id="table-custom"
            :headers="headers"
            :items="groupNIPs"
            :loading="loading"
            :options.sync="options"
            :server-items-length="pagination.totalItem"
            :footer-props="{
              'items-per-page-options': rowsPerPageItems
            }"
            loading-text="Loading... Please wait"
            item-key="no"
          >
            <template v-slot:[`item.groupstt`]="{ item }">
              {{ item.groupstt ? "ACTIVE" : "INACTIVE" }}
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-btn
                x-small
                color="#297BBF"
                class="mr-2 my-1 white--text"
                @click="handleEdit(item)"
                >Edit</v-btn
              >
              <v-btn
                x-small
                color="#FBB005"
                class="mr-2 my-1 white--text"
                @click="handleDelete(item)"
                >Hapus</v-btn
              >
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <EmployeeSearchDialog
      ref="employeeSearch"
      :lists="form.employees"
      value="NIP"
      @handle-pick="pickItem"
    />
    <v-dialog v-model="visible" persistent max-width="800">
      <v-card class="pa-5 rounded-lg" id="card-custom">
        <v-btn @click="visible = false" x-small icon class="float-right">
          <v-icon>close</v-icon>
        </v-btn>
        <v-card-text class="subtitle-2 px-0 font-weight-regular">
          Buat Group NIP
        </v-card-text>
        <v-divider class="mb-4" />
        <v-form
          id="form"
          ref="form"
          v-model="valid"
          lazy-validation
          @submit.prevent="save()"
        >
          <v-row>
            <v-col cols="4" class="py-1">
              <v-subheader class="caption headline-color font-weight-regular"
                >Unit Utama</v-subheader
              >
            </v-col>
            <v-col cols="8" class="py-1">
              <v-select
                placeholder="- Semua Unit Utama -"
                v-model="form.unit_utama_id"
                :items="units"
                :loading="loadingUnit"
                :disabled="form.groupid"
                outlined
                dense
                clearable
                item-text="singkatan"
                item-value="unit_utama_id"
                @change="changeUnitForm(1)"
                @click:clear="changeUnitForm(1)"
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4" class="py-1">
              <v-subheader class="caption headline-color font-weight-regular"
                >Unit Kerja</v-subheader
              >
            </v-col>
            <v-col cols="8" class="py-1">
              <v-select
                placeholder="- Semua Unit Kerja -"
                v-model="form.unit_kerja_2_id"
                :items="units2Form"
                :loading="loadingUnit"
                :disabled="form.groupid"
                outlined
                dense
                clearable
                item-text="singkatan"
                item-value="unit_kerja_2_id"
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4" class="py-1">
              <v-subheader class="caption headline-color font-weight-regular"
                >Nama Group</v-subheader
              >
            </v-col>
            <v-col cols="8" class="py-1">
              <v-text-field
                v-model="form.groupname"
                outlined
                dense
                :rules="[v => !!v || 'Nama Group harus diisi']"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4" class="py-1">
              <v-subheader class="caption headline-color font-weight-regular"
                >Aktif</v-subheader
              >
            </v-col>
            <v-col cols="8" class="py-1">
              <v-switch v-model="form.groupstt"></v-switch>
            </v-col>
          </v-row>
          <v-divider class="mb-2" />
          <v-row>
            <v-col cols="12" class="py-1">
              <v-subheader class="caption headline-color font-weight-regular"
                >List Pegawai</v-subheader
              >
              <v-data-table
                id="table-custom-pegawai"
                class="mt-3"
                :headers="employeeHeaders"
                :items="form.employees"
              >
                <template v-slot:[`item`]="{ item, index }">
                  <tr>
                    <td class="pt-2">
                      {{ item.NIP }}
                    </td>
                    <td class="pt-2">
                      {{ item.name }}
                    </td>
                    <td>
                      <v-btn
                        small
                        color="#FBB005"
                        class="ml-2 white--text"
                        @click="deleteItemEmployee(index)"
                        >Hapus</v-btn
                      >
                    </td>
                  </tr>
                </template>
              </v-data-table>
              <v-card-actions>
                <v-spacer />
                <v-btn
                  text
                  class="text-capitalize caption headline-color"
                  color="primary"
                  @click="addEmployee"
                  >Tambah<v-icon class="ml-2" color="primary"
                    >add_circle</v-icon
                  ></v-btn
                >
              </v-card-actions>
            </v-col>
          </v-row>
          <v-card-actions class="mt-3">
            <v-spacer />
            <v-btn
              small
              outlined
              color="#717171"
              class="px-12"
              @click="visible = false"
              >Batal</v-btn
            >
            <v-btn
              small
              color="#FBB005"
              class="px-12 subtitle-2 text-capitalize"
              :loading="formLoading"
              type="submit"
              :disabled="!valid"
              form="form"
              >Simpan</v-btn
            >
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import DataUtamaService from "@/services/resources/data.utama.service";
import GroupNIPService from "@/services/resources/group.nip.service";

const EmployeeSearchDialog = () => import("@/components/Dialog/EmployeeSearch");

export default {
  components: {
    EmployeeSearchDialog
  },
  data() {
    return {
      visible: false,
      valid: false,
      loading: false,
      loadingUnit: false,
      formLoading: false,
      filter: {
        search: null,
        unit_utama_id: null,
        unit_kerja_2_id: null
      },
      units: [],
      units2: [],
      units2Form: [],
      headers: [
        {
          text: "No",
          value: "no",
          sortable: false
        },
        {
          text: "Nama",
          value: "groupname",
          sortable: false
        },
        {
          text: "Unit Utama",
          value: "unit_utama_name",
          sortable: false
        },
        {
          text: "Unit Kerja",
          value: "unit_kerja_2_name",
          sortable: false
        },
        {
          text: "Status",
          value: "groupstt",
          sortable: false
        },
        { text: "", value: "actions", sortable: false }
      ],
      employeeHeaders: [
        {
          text: "NIP",
          value: "NIP",
          sortable: false,
          align: "center"
        },
        {
          text: "Nama",
          value: "name",
          sortable: false,
          align: "center"
        },
        {
          text: "Aksi",
          value: "actions",
          sortable: false,
          align: "center"
        }
      ],
      groupNIPs: [],
      pagination: {
        page: 1,
        itemsPerPage: 10,
        totalItem: 0,
        pageCount: 0
      },
      options: {
        sortBy: ["groupname"],
        sortDesc: [false]
      },
      rowsPerPageItems: [10, 20, 30, 40],
      form: {
        groupid: null,
        groupname: null,
        groupstt: true,
        unit_utama_id: null,
        unit_kerja_2_id: null,
        employees: []
      }
    };
  },
  watch: {
    options: {
      handler() {
        this.fetchListDebounce(this.getListGroupNIP);
      },
      deep: true
    },
    paginationProperty: function() {
      this.options.page = 1;
      this.fetchListDebounce(this.getListGroupNIP);
    },
    visible(val) {
      if (!val) {
        this.$refs.form.reset();
        this.form.employees = [];
      }
    }
  },
  computed: {
    paginationProperty() {
      return [
        this.filter.search,
        this.filter.unit_utama_id,
        this.filter.unit_kerja_2_id
      ].join();
    }
  },
  methods: {
    changeUnit(type = 1) {
      if (type == 1) {
        this.units2 = this.units.find(
          d => d.unit_kerja_2_id == this.unit2
        )?.unit_kerja2;
        this.unit2 = null;
      }
    },
    changeUnitForm(type = 1) {
      if (type == 1) {
        this.units2Form = this.units.find(
          d => d.unit_utama_id == this.form.unit_utama_id
        )?.unit_kerja2;
        this.form.unit_kerja_2_id = null;
      }
    },
    populateUnit() {
      this.units2Form = this.units.find(
        d => d.unit_utama_id == this.form.unit_utama_id
      )?.unit_kerja2;
    },
    deleteItemEmployee(index) {
      this.form.employees.splice(index, 1);
    },
    handleRemoveEmployee(item) {
      let index = this.form.employees.findIndex(d => d.NIP == item.NIP);
      this.form.employees.splice(index, 1);
    },
    addEmployee() {
      this.$refs.employeeSearch.change(true);
    },
    pickItem(item) {
      if (item) {
        let index = this.form.employees.findIndex(d => d.NIP == item.NIP);
        if (index < 0) {
          this.form.employees.push({
            NIP: item.NIP,
            name: item.nama
          });
        }
      }
    },
    handleEdit(item) {
      this.visible = true;
      this.getDetailGroupNIP(item.groupid);
    },
    handleDelete(item) {
      this.$confirm({
        title: "Hapus Group NIP",
        message: `Apakah Anda yakin akan menghapus group NIP ini?`,
        button: {
          no: "Batal",
          yes: "Hapus"
        },
        callback: confirm => {
          if (confirm) {
            this.deleteGroupNIP({
              groupid: item.groupid,
              unit_utama_id: item.unit_utama_id,
              unit_kerja_2_id: item.unit_kerja_2_id
            });
          }
        }
      });
    },
    save() {
      if (this.$refs.form.validate()) {
        let formData = new FormData();
        formData.append("action", this.form.groupid ? 2 : 1);
        if (this.form.groupid) {
          formData.append("groupid", this.form.groupid);
        }
        formData.append("groupname", this.form.groupname);
        formData.append("groupstt", this.form.groupstt ? 1 : 0);
        formData.append("unit_utama_id", this.form.unit_utama_id);
        formData.append("unit_kerja_2_id", this.form.unit_kerja_2_id);
        this.form.employees.forEach(employee => {
          formData.append("NIP[]", employee.NIP);
        });
        this.saveGroupNIP(formData);
      }
    },
    // Service
    async getListUnit() {
      try {
        this.loadingUnit = true;
        await DataUtamaService.getUnitKerjaAll({
          platform: this.isPresensi ? "presensi" : "simpeg"
        })
          .then(response => {
            const { status, data } = response.data;
            if (status) {
              this.units = data;
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.loadingUnit = false));
      } catch (err) {
        console.error(err);
      }
    },
    async getListGroupNIP() {
      const { page, itemsPerPage, sortBy, sortDesc } = this.options;
      let orderBy = [];
      sortBy.map((d, i) => {
        let sort = sortDesc[i] ? "desc" : "asc";
        orderBy = [...orderBy, d, sort];
        return d;
      });
      try {
        this.loading = true;
        await GroupNIPService.getList({
          filter: {
            search: this.filter.search,
            unit_utama_id: this.filter.unit_utama_id,
            unit_kerja_2_id: this.filter.unit_kerja_2_id
          },
          orderBy,
          pageCurrent: page,
          dataPerPage: itemsPerPage
        })
          .then(response => {
            const { status, data } = response.data;
            const { list, pageTotal, countTotal } = data;
            if (status) {
              let groupNIPs = list;
              groupNIPs.map((data, index) => {
                data.no = itemsPerPage * (page - 1) + (index + 1);
                return data;
              });

              this.groupNIPs = groupNIPs;
              this.pagination.totalItem = parseInt(countTotal);
              this.pagination.pageCount = parseInt(pageTotal);
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.loading = false));
      } catch (err) {
        console.error(err);
      }
    },
    async saveGroupNIP(data) {
      try {
        this.formLoading = true;
        await GroupNIPService.save(data)
          .then(response => {
            const { status, message } = response.data;
            if (status) {
              this.getListGroupNIP();
              this.visible = false;
              this.$store.commit("snackbar/setSnack", {
                show: true,
                message: message,
                color: "success"
              });
            } else {
              this.$store.commit("snackbar/setSnack", {
                show: true,
                message: message,
                color: "error"
              });
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => {
            this.formLoading = false;
          });
      } catch (err) {
        console.error(err);
      }
    },
    async getDetailGroupNIP(id) {
      try {
        this.loading = true;
        await GroupNIPService.getDetail(id)
          .then(response => {
            const { status, data } = response.data;
            if (status) {
              this.form = this.$_.merge(this.form, data);
              this.form.employees = data.DtDetail.map(detail => {
                return {
                  ...detail,
                  name: detail.nama
                };
              });
              this.populateUnit();
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.loading = false));
      } catch (err) {
        console.error(err);
      }
    },
    async deleteGroupNIP(payload) {
      try {
        this.formLoading = true;
        await GroupNIPService.delete(payload)
          .then(response => {
            const { status, message } = response.data;
            if (status) {
              this.getListGroupNIP();
              this.$store.commit("snackbar/setSnack", {
                show: true,
                message: message,
                color: "success"
              });
            } else {
              this.$store.commit("snackbar/setSnack", {
                show: true,
                message: message,
                color: "error"
              });
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.formLoading = false));
      } catch (err) {
        console.error(err);
      }
    }
  },
  mounted() {
    this.getListUnit();
  }
};
</script>
<style lang="scss">
#table-custom.v-data-table {
  color: #717171 !important;
  .v-data-table__wrapper > table > thead {
    background-color: #fdfdfd;
  }
  .v-data-table__wrapper > table > thead > tr > th {
    font-size: 0.775rem;
    font-weight: 400;
  }
  .v-data-table__wrapper > table > tbody > tr > td {
    font-size: 0.775rem;
  }
}
#card-custom .v-input {
  font-size: 0.775rem;
}
</style>
